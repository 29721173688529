// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-container {
  width: 80px;
  height: 80px;
  margin-left: 50px;
  display: flex;
  justify-content: center;
}
.circle {
  position: absolute;
  margin: 1px;
  top: 0px;
}

.arrow {
  margin-top: 20px;
  position: relative;
  left: 1px;
  animation-name: clickme;
  animation-delay: 0s;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  animation-iteration-count: infinite;
  transform-origin: 50% 20.5px;
  will-change: transform;
}
@keyframes clickme {
  0%,
  20% {
    transform: translateY(0);
  }

  10% {
    opacity: 1;
  }

  100% {
    transform: translateY(-36px);
    opacity: 0.01;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/arrow/arrow.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,uBAAuB;EACvB,mBAAmB;EACnB,wBAAwB;EACxB,+DAA+D;EAC/D,mCAAmC;EACnC,4BAA4B;EAC5B,sBAAsB;AACxB;AACA;EACE;;IAEE,wBAAwB;EAC1B;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,4BAA4B;IAC5B,aAAa;EACf;AACF","sourcesContent":[".arrow-container {\n  width: 80px;\n  height: 80px;\n  margin-left: 50px;\n  display: flex;\n  justify-content: center;\n}\n.circle {\n  position: absolute;\n  margin: 1px;\n  top: 0px;\n}\n\n.arrow {\n  margin-top: 20px;\n  position: relative;\n  left: 1px;\n  animation-name: clickme;\n  animation-delay: 0s;\n  animation-duration: 1.5s;\n  animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);\n  animation-iteration-count: infinite;\n  transform-origin: 50% 20.5px;\n  will-change: transform;\n}\n@keyframes clickme {\n  0%,\n  20% {\n    transform: translateY(0);\n  }\n\n  10% {\n    opacity: 1;\n  }\n\n  100% {\n    transform: translateY(-36px);\n    opacity: 0.01;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
