// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-list-container-parent {
  position: absolute;
  top: 80px;
  left: 220px;
  width: calc(100% - 220px);
  height: calc(100% - 125px);
  overflow: visible;
}
.book-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 220px;
  width: calc(100% - 220px);
}
.book-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  -webkit-user-select: text;
          user-select: text;
}

.booklist-shelf-list {
  width: 169px;
  height: 31px;
  opacity: 1;
  box-sizing: border-box;
  margin-left: 16px;
  font-size: 15px;
  line-height: 31px;
  border-radius: 5px;
  cursor: pointer;
}
.delete-shelf-icon {
  cursor: pointer;
  margin-left: 20px;
}
.icon-cover {
  font-weight: 500;
}
.booklist-shelf-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booklist-manage-container {
  cursor: pointer;
}
.book-manage-title {
  font-size: 15px;
  font-weight: 500;
  padding: 6px 10px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/lists/bookList/booklist.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,0BAA0B;EAC1B,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".book-list-container-parent {\n  position: absolute;\n  top: 80px;\n  left: 220px;\n  width: calc(100% - 220px);\n  height: calc(100% - 125px);\n  overflow: visible;\n}\n.book-list-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: absolute;\n  bottom: 10px;\n  left: 220px;\n  width: calc(100% - 220px);\n}\n.book-list-container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  align-content: flex-start;\n  user-select: text;\n}\n\n.booklist-shelf-list {\n  width: 169px;\n  height: 31px;\n  opacity: 1;\n  box-sizing: border-box;\n  margin-left: 16px;\n  font-size: 15px;\n  line-height: 31px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n.delete-shelf-icon {\n  cursor: pointer;\n  margin-left: 20px;\n}\n.icon-cover {\n  font-weight: 500;\n}\n.booklist-shelf-container {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.booklist-manage-container {\n  cursor: pointer;\n}\n.book-manage-title {\n  font-size: 15px;\n  font-weight: 500;\n  padding: 6px 10px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
