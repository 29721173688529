// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-preview {
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}
.image {
  z-index: 5;
}
.image-operation {
  position: fixed;
  left: calc(50% - 160px);
  bottom: 30px;
  font-size: 25px;
  z-index: 6;
  background-color: black;
  opacity: 0.3;
  padding: 10px;
  border-radius: 20px;
}
.zoom-in-icon,
.zoom-out-icon,
.save-icon,
.clockwise-icon,
.counterclockwise-icon {
  margin: 20px;
  cursor: pointer;
}
.image-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/imageViewer/imageViewer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;;EAEb,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;;;;;EAKE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,QAAQ;EACR,SAAS;AACX","sourcesContent":[".image-preview {\n  width: 100vw;\n  height: 100vh;\n\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  overflow: scroll;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 50;\n}\n.image {\n  z-index: 5;\n}\n.image-operation {\n  position: fixed;\n  left: calc(50% - 160px);\n  bottom: 30px;\n  font-size: 25px;\n  z-index: 6;\n  background-color: black;\n  opacity: 0.3;\n  padding: 10px;\n  border-radius: 20px;\n}\n.zoom-in-icon,\n.zoom-out-icon,\n.save-icon,\n.clockwise-icon,\n.counterclockwise-icon {\n  margin: 20px;\n  cursor: pointer;\n}\n.image-background {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0px;\n  left: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
