// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-dialog-container {
  width: 180px;
  opacity: 1;
  position: absolute;
  left: 425px;
  padding: 5px;
  top: 65px;
  z-index: 5;
  border-radius: 5px;
  transition: all 0.1s ease;
  overflow: hidden;
  animation: popup 0.1s ease-in-out 0s 1;
}

.sort-by-category-list,
.sort-by-order-list {
  font-size: 15px;
  font-weight: 500;
  opacity: 1;
  line-height: 30px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 3px;
}
.sort-dialog-seperator {
  width: 100%;
  height: 0px;
  margin: 6px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/dialogs/sortDialog/sortDialog.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;;EAEE,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,iBAAiB;EACjB,wBAAwB;EACxB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,WAAW;EACX,eAAe;AACjB","sourcesContent":[".sort-dialog-container {\n  width: 180px;\n  opacity: 1;\n  position: absolute;\n  left: 425px;\n  padding: 5px;\n  top: 65px;\n  z-index: 5;\n  border-radius: 5px;\n  transition: all 0.1s ease;\n  overflow: hidden;\n  animation: popup 0.1s ease-in-out 0s 1;\n}\n\n.sort-by-category-list,\n.sort-by-order-list {\n  font-size: 15px;\n  font-weight: 500;\n  opacity: 1;\n  line-height: 30px;\n  width: calc(100% - 30px);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n  padding-left: 15px;\n  padding-right: 15px;\n  border-radius: 3px;\n}\n.sort-dialog-seperator {\n  width: 100%;\n  height: 0px;\n  margin: 6px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
