// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-cover {
  width: 105px;
  height: 137px;
  transform-origin: 0% 0%;
}
.cover-banner {
  float: left;
  width: 90px;
  height: 30px;
  margin-top: 10px;
  line-height: 30px;
  font-weight: 500;
  text-indent: 10px;
  font-size: 15px;
}
.cover-title {
  float: left;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.9;
  width: 90%;
  height: 54px;
  text-align: left;
  line-height: 18px;
  position: relative;
  top: 10px;
  margin-left: 5px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.cover-footer {
  width: 100%;
  text-align: center;
  font-size: 13px;
  opacity: 0.3;
  position: absolute;
  bottom: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/emptyCover/emptyCover.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,gBAAgB;EAChB,qBAAqB;EACrB,uBAAuB;EACvB,oBAAoB;EACpB,4BAA4B;EAC5B,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".empty-cover {\n  width: 105px;\n  height: 137px;\n  transform-origin: 0% 0%;\n}\n.cover-banner {\n  float: left;\n  width: 90px;\n  height: 30px;\n  margin-top: 10px;\n  line-height: 30px;\n  font-weight: 500;\n  text-indent: 10px;\n  font-size: 15px;\n}\n.cover-title {\n  float: left;\n  font-size: 15px;\n  font-weight: 500;\n  opacity: 0.9;\n  width: 90%;\n  height: 54px;\n  text-align: left;\n  line-height: 18px;\n  position: relative;\n  top: 10px;\n  margin-left: 5px;\n  word-wrap: break-word;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 3;\n  overflow: hidden;\n}\n.cover-footer {\n  width: 100%;\n  text-align: center;\n  font-size: 13px;\n  opacity: 0.3;\n  position: absolute;\n  bottom: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
