// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-box-container {
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  bottom: 0px;
  width: 500px;
  height: 300px;
  left: calc(50% - 250px);
  z-index: 10;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media screen and (max-width: 576px) {
  .popup-box-container {
    width: 90%;
    left: 5%;
  }
}
.popup-menu-triangle-down {
  float: left;
  margin-left: 59px;
  font-size: 16px;
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  z-index: 0;
}
.popup-menu-triangle-up {
  transform: rotate(180deg);
  position: absolute;
  bottom: 172px;
  margin-left: 60px;
  font-size: 16px;
  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  z-index: 0;
}
.delete-highlighter-container {
  position: relative;
}

.popup-close {
  position: absolute;
  top: -30px;
  right: 70px;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/popups/popupBox/popupMenu.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,8BAA8B;EAC9B,+BAA+B;AACjC;AACA;EACE;IACE,UAAU;IACV,QAAQ;EACV;AACF;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,4CAA4C;EAC5C,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,4CAA4C;EAC5C,UAAU;AACZ;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".popup-box-container {\n  opacity: 1;\n  border-radius: 10px;\n  position: absolute;\n  bottom: 0px;\n  width: 500px;\n  height: 300px;\n  left: calc(50% - 250px);\n  z-index: 10;\n  border-bottom-left-radius: 0px;\n  border-bottom-right-radius: 0px;\n}\n@media screen and (max-width: 576px) {\n  .popup-box-container {\n    width: 90%;\n    left: 5%;\n  }\n}\n.popup-menu-triangle-down {\n  float: left;\n  margin-left: 59px;\n  font-size: 16px;\n  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);\n  z-index: 0;\n}\n.popup-menu-triangle-up {\n  transform: rotate(180deg);\n  position: absolute;\n  bottom: 172px;\n  margin-left: 60px;\n  font-size: 16px;\n  text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);\n  z-index: 0;\n}\n.delete-highlighter-container {\n  position: relative;\n}\n\n.popup-close {\n  position: absolute;\n  top: -30px;\n  right: 70px;\n  cursor: pointer;\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
