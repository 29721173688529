// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-content-list {
  float: left;
  width: calc(100% - 15px);
  margin-left: 18px;
}
.book-content-name {
  display: block;
  padding: 10px 0px;
  width: 90%;
  font-size: 15px;
  line-height: 18px;
  opacity: 1;
  float: left;
  transition: none;
  /* text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden; */
}
.book-subcontent-name {
  display: block;
  width: 220px;
  float: left;
  font-size: 13px;
  line-height: 15px;
  opacity: 0.64;
  padding: 8px;
}
.book-subcontent-list {
  margin-left: 18px;
}
.content-dropdown {
  float: right;
  margin-top: 15px;
  position: relative;
  right: 15px;
  opacity: 0.5;
  font-size: 13px;
  cursor: pointer;
  transition: transform 0.1s ease;
}
`, "",{"version":3,"sources":["webpack://./src/containers/lists/contentList/contentList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wBAAwB;EACxB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB;;;;qBAImB;AACrB;AACA;EACE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,eAAe;EACf,+BAA+B;AACjC","sourcesContent":[".book-content-list {\n  float: left;\n  width: calc(100% - 15px);\n  margin-left: 18px;\n}\n.book-content-name {\n  display: block;\n  padding: 10px 0px;\n  width: 90%;\n  font-size: 15px;\n  line-height: 18px;\n  opacity: 1;\n  float: left;\n  transition: none;\n  /* text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 1;\n  overflow: hidden; */\n}\n.book-subcontent-name {\n  display: block;\n  width: 220px;\n  float: left;\n  font-size: 13px;\n  line-height: 15px;\n  opacity: 0.64;\n  padding: 8px;\n}\n.book-subcontent-list {\n  margin-left: 18px;\n}\n.content-dropdown {\n  float: right;\n  margin-top: 15px;\n  position: relative;\n  right: 15px;\n  opacity: 0.5;\n  font-size: 13px;\n  cursor: pointer;\n  transition: transform 0.1s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
