// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manager {
  width: 100%;
  height: 100%;
}
.token-dialog-token-text {
  width: 100%;
  height: 29px;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
}
.auth-page-close-icon {
  font-size: 60px !important;
  float: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/redirect/manager.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,0BAA0B;EAC1B,sBAAsB;AACxB","sourcesContent":[".manager {\n  width: 100%;\n  height: 100%;\n}\n.token-dialog-token-text {\n  width: 100%;\n  height: 29px;\n  opacity: 1;\n  font-size: 15px;\n  font-weight: 500;\n  line-height: 29px;\n  text-align: center;\n  cursor: pointer;\n}\n.auth-page-close-icon {\n  font-size: 60px !important;\n  float: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
