// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manager {
  width: 100%;
  height: 100%;
}
.arrow-text {
  font-size: 20px;
}

.auth-page-close-icon {
  font-size: 60px !important;
  float: none !important;
}
.mobile-warning {
  text-align: center;
  font-size: 22px;
  padding: 70px 30px;
  line-height: 30px;
}
.drag-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 9;
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.drag-info {
  width: 200px;
  height: 300px;
  position: absolute;
  top: 80px;
  right: 0px;
  text-align: center;
}
.waring-title {
  text-align: center;
  margin: 50px 0px 20px;
  font-size: 40px;
  font-weight: 600;
}
.waring-pic {
  width: 100vw;
  position: absolute;
  bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/manager/manager.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,UAAU;AACZ;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;AACA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".manager {\n  width: 100%;\n  height: 100%;\n}\n.arrow-text {\n  font-size: 20px;\n}\n\n.auth-page-close-icon {\n  font-size: 60px !important;\n  float: none !important;\n}\n.mobile-warning {\n  text-align: center;\n  font-size: 22px;\n  padding: 70px 30px;\n  line-height: 30px;\n}\n.drag-background {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0px;\n  left: 0px;\n  z-index: 9;\n}\n@keyframes fade-in-out {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.drag-info {\n  width: 200px;\n  height: 300px;\n  position: absolute;\n  top: 80px;\n  right: 0px;\n  text-align: center;\n}\n.waring-title {\n  text-align: center;\n  margin: 50px 0px 20px;\n  font-size: 40px;\n  font-weight: 600;\n}\n.waring-pic {\n  width: 100vw;\n  position: absolute;\n  bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
