// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paragraph-character-container {
  margin-bottom: 20px;
}
.paragraph-character-setting {
  margin: 0px 22px;
  width: calc(100% - 44px);
}
.general-setting-title {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}
.general-setting-dropdown {
  width: 220px;
  height: 30px;
  margin-right: 5px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  position: relative;
  left: 15px;
  border-radius: 5px;
}
.general-setting-option {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/readerSettings/dropdownList/dropdownList.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".paragraph-character-container {\n  margin-bottom: 20px;\n}\n.paragraph-character-setting {\n  margin: 0px 22px;\n  width: calc(100% - 44px);\n}\n.general-setting-title {\n  font-size: 15px;\n  font-weight: 500;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.general-setting-dropdown {\n  width: 220px;\n  height: 30px;\n  margin-right: 5px;\n  font-size: 15px;\n  font-weight: 500;\n  text-align: center;\n  cursor: pointer;\n  position: relative;\n  left: 15px;\n  border-radius: 5px;\n}\n.general-setting-option {\n  font-size: 15px;\n  font-weight: 500;\n  line-height: 27px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
