// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-desk-container {
  width: 346px;
  height: 480px;
  position: absolute;
  left: calc(50vw - 173px);
  top: calc(50vh - 240px);
  z-index: 20;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
}
.tip-close-icon {
  margin-top: 10px;
  margin-right: 10px;
}
.download-desk-animation {
  position: absolute;
  left: 25px;
  bottom: -20px;
  z-index: -1;
}
.download-desk-title {
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.download-desk-subtile {
  font-size: 15px;
  opacity: 0.6;
  font-weight: 500;
  text-align: center;
  padding: 20px;
  padding-top: 10px;
  line-height: 17px;
}
.download-desk-feature-item {
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  margin: 30px;
  margin-top: 10px;
  text-indent: 2rem;
}
.download-desk-button {
  width: 100px;
  height: 32px;
  opacity: 1;
  line-height: 32px;
  text-align: center;
  margin: 12px 175px;
  position: absolute;
  cursor: pointer;
  z-index: 25;
  border-radius: 16px;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/components/dialogs/TipDialog/updateInfo.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,wBAAwB;EACxB,uBAAuB;EACvB,WAAW;EACX,sCAAsC;EACtC,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".download-desk-container {\n  width: 346px;\n  height: 480px;\n  position: absolute;\n  left: calc(50vw - 173px);\n  top: calc(50vh - 240px);\n  z-index: 20;\n  animation: popup 0.1s ease-in-out 0s 1;\n  border-radius: 5px;\n}\n.tip-close-icon {\n  margin-top: 10px;\n  margin-right: 10px;\n}\n.download-desk-animation {\n  position: absolute;\n  left: 25px;\n  bottom: -20px;\n  z-index: -1;\n}\n.download-desk-title {\n  font-size: 18px;\n  font-weight: 500;\n  opacity: 1;\n  text-align: center;\n  width: 100%;\n  margin-top: 20px;\n}\n.download-desk-subtile {\n  font-size: 15px;\n  opacity: 0.6;\n  font-weight: 500;\n  text-align: center;\n  padding: 20px;\n  padding-top: 10px;\n  line-height: 17px;\n}\n.download-desk-feature-item {\n  font-size: 15px;\n  line-height: 23px;\n  font-weight: 500;\n  margin: 30px;\n  margin-top: 10px;\n  text-indent: 2rem;\n}\n.download-desk-button {\n  width: 100px;\n  height: 32px;\n  opacity: 1;\n  line-height: 32px;\n  text-align: center;\n  margin: 12px 175px;\n  position: absolute;\n  cursor: pointer;\n  z-index: 25;\n  border-radius: 16px;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
