// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-shelf-icon {
  cursor: pointer;
  margin-left: 20px;
}
.booklist-delete-container {
  border: 2px solid rgb(231, 69, 69);
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: rgb(231, 69, 69);
  cursor: pointer;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/lists/deletedBookList/booklist.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".delete-shelf-icon {\n  cursor: pointer;\n  margin-left: 20px;\n}\n.booklist-delete-container {\n  border: 2px solid rgb(231, 69, 69);\n  text-align: center;\n  height: 30px;\n  line-height: 30px;\n  color: rgb(231, 69, 69);\n  cursor: pointer;\n  border-radius: 5px;\n  padding-left: 10px;\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
