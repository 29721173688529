// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.book-list-view {
  font-size: 14px;
  clear: both;
  width: 100px;
  z-index: 1;
  height: 20px;
  overflow: hidden;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: all 0.1s ease;
}
.view-name {
  display: inline-block;
  width: 60px;
  text-align: center;
}
.card-list-mode {
  line-height: 17px;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
}

.book-list-item-box {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.view-mode-down {
  position: absolute;
  top: 15px;
  right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/viewMode/viewMode.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,yBAAyB;AAC3B;AACA;EACE,qBAAqB;EACrB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb","sourcesContent":[".book-list-view {\n  font-size: 14px;\n  clear: both;\n  width: 100px;\n  z-index: 1;\n  height: 20px;\n  overflow: hidden;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  transition: all 0.1s ease;\n}\n.view-name {\n  display: inline-block;\n  width: 60px;\n  text-align: center;\n}\n.card-list-mode {\n  line-height: 17px;\n  cursor: pointer;\n  display: inline-block;\n  font-size: 17px;\n}\n\n.book-list-item-box {\n  position: relative;\n  top: 0px;\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll;\n}\n.view-mode-down {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
