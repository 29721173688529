// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-dialog-container {
  width: 200px;
  /* height: 340px; */
  opacity: 1;
  position: fixed;
  z-index: 9;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
  overflow: hidden;
  overflow-x: hidden;
}

.action-dialog-actions-container {
  font-size: 15px;
  text-align: center;
  line-height: 14px;
  opacity: 1;
  padding: 5px;
  /* position: absolute;
  top: 83px;
  left: 29px; */
}
.action-dialog-add,
.action-dialog-delete,
.action-dialog-edit {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  border-radius: 3px;
}

.action-name {
  font-size: 15px;
  text-align: center;
  line-height: 25px;
  margin-left: 15px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/dialogs/actionDialog/actionDialog.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,UAAU;EACV,eAAe;EACf,UAAU;EACV,sCAAsC;EACtC,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ;;eAEa;AACf;AACA;;;EAGE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".action-dialog-container {\n  width: 200px;\n  /* height: 340px; */\n  opacity: 1;\n  position: fixed;\n  z-index: 9;\n  animation: popup 0.1s ease-in-out 0s 1;\n  border-radius: 5px;\n  overflow: hidden;\n  overflow-x: hidden;\n}\n\n.action-dialog-actions-container {\n  font-size: 15px;\n  text-align: center;\n  line-height: 14px;\n  opacity: 1;\n  padding: 5px;\n  /* position: absolute;\n  top: 83px;\n  left: 29px; */\n}\n.action-dialog-add,\n.action-dialog-delete,\n.action-dialog-edit {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  padding: 4px 15px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  padding-left: 10px;\n  border-radius: 3px;\n}\n\n.action-name {\n  font-size: 15px;\n  text-align: center;\n  line-height: 25px;\n  margin-left: 15px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
