// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-main-container {
  position: fixed;
  bottom: 42px;
  left: -21px;
  width: 140px;
  height: 40px;
  border-radius: 20px;
  background-color: #8b8b8b;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 5px;
}
.back-main-container:hover {
  animation: stretch 0.3s forwards;
}

@keyframes stretch {
  0% {
    width: 30px;
  }
  100% {
    width: 140px;
  }
}
@keyframes shrink {
  0% {
    width: 140px;
  }
  100% {
    width: 30px;
  }
}

.back-home-home,
.back-home-day,
.back-home-night,
.back-home-eye,
.back-home-dropdown {
  color: #fcfcfc;
}
.back-home-home:hover,
.back-home-day:hover,
.back-home-night:hover,
.back-home-eye:hover,
.back-home-dropdown:hover {
  opacity: 0.6;
}
.back-home-dropdown {
  font-size: 15px;
  transform: rotate(-90deg);
  position: relative;
  left: 5px;
}
.back-home-eye {
  font-size: 22px;
}
.back-home-day {
  font-size: 22px;
}
.back-home-night {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pdfWidget/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;EACd;EACA;IACE,WAAW;EACb;AACF;;AAEA;;;;;EAKE,cAAc;AAChB;AACA;;;;;EAKE,YAAY;AACd;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".back-main-container {\n  position: fixed;\n  bottom: 42px;\n  left: -21px;\n  width: 140px;\n  height: 40px;\n  border-radius: 20px;\n  background-color: #8b8b8b;\n  z-index: 10;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  cursor: pointer;\n  padding-left: 25px;\n  padding-right: 5px;\n}\n.back-main-container:hover {\n  animation: stretch 0.3s forwards;\n}\n\n@keyframes stretch {\n  0% {\n    width: 30px;\n  }\n  100% {\n    width: 140px;\n  }\n}\n@keyframes shrink {\n  0% {\n    width: 140px;\n  }\n  100% {\n    width: 30px;\n  }\n}\n\n.back-home-home,\n.back-home-day,\n.back-home-night,\n.back-home-eye,\n.back-home-dropdown {\n  color: #fcfcfc;\n}\n.back-home-home:hover,\n.back-home-day:hover,\n.back-home-night:hover,\n.back-home-eye:hover,\n.back-home-dropdown:hover {\n  opacity: 0.6;\n}\n.back-home-dropdown {\n  font-size: 15px;\n  transform: rotate(-90deg);\n  position: relative;\n  left: 5px;\n}\n.back-home-eye {\n  font-size: 22px;\n}\n.back-home-day {\n  font-size: 22px;\n}\n.back-home-night {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
