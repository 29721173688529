// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-page-container {
  position: absolute;
  top: 75px;
  left: 203px;
  width: calc(100vw - 203px);
  height: calc(100vh - 75px);
  overflow: hidden;
}
.empty-page-illustration {
  height: 100%;
}
.empty-illustration-container {
  height: 450px;
  position: absolute;
  right: 0;
  bottom: 0;

  z-index: -1;
  overflow: hidden;
}
.empty-page-info-container {
  position: absolute;
  left: 30px;
  top: 80px;
}
.empty-page-info-main {
  font-size: 32px;
  line-height: 50px;
  opacity: 1;
}
.empty-page-info-sub {
  width: 400px;
  font-size: 24px;

  line-height: 30px;
  opacity: 0.49;
}
`, "",{"version":3,"sources":["webpack://./src/containers/emptyPage/emptyPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,0BAA0B;EAC1B,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,SAAS;;EAET,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,YAAY;EACZ,eAAe;;EAEf,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".empty-page-container {\n  position: absolute;\n  top: 75px;\n  left: 203px;\n  width: calc(100vw - 203px);\n  height: calc(100vh - 75px);\n  overflow: hidden;\n}\n.empty-page-illustration {\n  height: 100%;\n}\n.empty-illustration-container {\n  height: 450px;\n  position: absolute;\n  right: 0;\n  bottom: 0;\n\n  z-index: -1;\n  overflow: hidden;\n}\n.empty-page-info-container {\n  position: absolute;\n  left: 30px;\n  top: 80px;\n}\n.empty-page-info-main {\n  font-size: 32px;\n  line-height: 50px;\n  opacity: 1;\n}\n.empty-page-info-sub {\n  width: 400px;\n  font-size: 24px;\n\n  line-height: 30px;\n  opacity: 0.49;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
