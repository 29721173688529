// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-search-box {
  width: calc(100% - 20px);
  height: 39px;
  border-radius: 22px;
  border-style: none;
  outline: none;
  font-size: 15px;
  padding: 0px;
  padding-left: 20px;
}
.header-search-box::placeholder {
  font-size: 15px;
  line-height: 39px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 40px);
}
.header-search-icon {
  font-size: 22px;
  display: inline-block;
  opacity: 0.6;
  cursor: pointer;
}
.header-search-text {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 15px;
  display: inline-block;
  width: 40px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-search-text:hover {
  border-radius: 50%;
}
.search-text {
  font-size: 18px;
  position: relative;
  right: 180px;
  bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/searchBox/searchBox.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,qBAAqB;EACrB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb","sourcesContent":[".header-search-box {\n  width: calc(100% - 20px);\n  height: 39px;\n  border-radius: 22px;\n  border-style: none;\n  outline: none;\n  font-size: 15px;\n  padding: 0px;\n  padding-left: 20px;\n}\n.header-search-box::placeholder {\n  font-size: 15px;\n  line-height: 39px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  width: calc(100% - 40px);\n}\n.header-search-icon {\n  font-size: 22px;\n  display: inline-block;\n  opacity: 0.6;\n  cursor: pointer;\n}\n.header-search-text {\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  font-size: 15px;\n  display: inline-block;\n  width: 40px;\n  height: 100%;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.header-search-text:hover {\n  border-radius: 50%;\n}\n.search-text {\n  font-size: 18px;\n  position: relative;\n  right: 180px;\n  bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
