// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-more-actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 25px;
  z-index: 10;
  animation: popup 0.1s ease-in-out 0s 1;
  border-radius: 5px;
  width: 150px;
  padding: 5px;
}
.select-more-actions-container {
  display: inline-block;
}
.select-book-action {
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/selectBook/selectBook.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,sCAAsC;EACtC,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;AACA;EACE,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".select-more-actions {\n  display: flex;\n  flex-direction: column;\n  position: absolute;\n  bottom: 25px;\n  z-index: 10;\n  animation: popup 0.1s ease-in-out 0s 1;\n  border-radius: 5px;\n  width: 150px;\n  padding: 5px;\n}\n.select-more-actions-container {\n  display: inline-block;\n}\n.select-book-action {\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
