// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-export-all {
  line-height: 18px;
  display: inline-block;
  transform: rotate(-90deg) scale(0.7);
}
`, "",{"version":3,"sources":["webpack://./src/components/dialogs/aboutDialog/aboutDialog.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,oCAAoC;AACtC","sourcesContent":[".icon-export-all {\n  line-height: 18px;\n  display: inline-block;\n  transform: rotate(-90deg) scale(0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
