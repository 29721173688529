// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-size-setting {
  width: calc(100% - 44px);
  margin: 0px 22px;
}
.font-size-title {
  font-size: 15px;
  line-height: 27px;
  padding-bottom: 10px;
  font-weight: 500;
  margin-bottom: -5px;
  margin-top: 10px;
}
.ultra-small-size {
  float: left;
  font-size: 15px;
  line-height: 27px;
  position: relative;
}

.ultra-large-size {
  float: right;
  font-size: 40px;
  line-height: 28px;
  position: relative;
  text-align: left;
}
.font-size-selector {
  width: 200px;
  height: 28px;
  margin-left: 0px !important;
  position: relative;
}

.font-size-text {
  width: 100%;
  text-align: center;
  margin-top: 8px;
  float: left;
  display: block;
  font-size: 13px;
}
.font-size-demo {
  text-align: center;
  position: relative;
  top: 10px;
}
.slide-icon {
  font-size: 12px;
  margin: 5px;
  cursor: pointer;
}
.icon-add .slide-icon {
  font-weight: 600 !important;
}
.input-value {
  width: 50px;
  height: 20px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/readerSettings/sliderList/sliderList.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,cAAc;EACd,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".font-size-setting {\n  width: calc(100% - 44px);\n  margin: 0px 22px;\n}\n.font-size-title {\n  font-size: 15px;\n  line-height: 27px;\n  padding-bottom: 10px;\n  font-weight: 500;\n  margin-bottom: -5px;\n  margin-top: 10px;\n}\n.ultra-small-size {\n  float: left;\n  font-size: 15px;\n  line-height: 27px;\n  position: relative;\n}\n\n.ultra-large-size {\n  float: right;\n  font-size: 40px;\n  line-height: 28px;\n  position: relative;\n  text-align: left;\n}\n.font-size-selector {\n  width: 200px;\n  height: 28px;\n  margin-left: 0px !important;\n  position: relative;\n}\n\n.font-size-text {\n  width: 100%;\n  text-align: center;\n  margin-top: 8px;\n  float: left;\n  display: block;\n  font-size: 13px;\n}\n.font-size-demo {\n  text-align: center;\n  position: relative;\n  top: 10px;\n}\n.slide-icon {\n  font-size: 12px;\n  margin: 5px;\n  cursor: pointer;\n}\n.icon-add .slide-icon {\n  font-weight: 600 !important;\n}\n.input-value {\n  width: 50px;\n  height: 20px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
