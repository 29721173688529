// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-list-container-parent {
  position: absolute;
  top: 75px;
  left: 200px;
  width: calc(100% - 200px);
  height: calc(100% - 75px);
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/containers/lists/noteList/noteList.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":[".note-list-container-parent {\n  position: absolute;\n  top: 75px;\n  left: 200px;\n  width: calc(100% - 200px);\n  height: calc(100% - 75px);\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
