// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.html-viewer-page {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 40px;
  bottom: 40px;
  z-index: 5;
  -webkit-user-select: text;
          user-select: text;
}
.scrolling-html-viewer-page {
  overflow-y: auto;
  overflow-x: hidden;
}
.scrolling-html-viewer-page:hover::-webkit-scrollbar-thumb,
.scrolling-html-viewer-page:active::-webkit-scrollbar-thumb,
.scrolling-html-viewer-page:focus::-webkit-scrollbar-thumb {
  display: block;
}
.scrolling-html-viewer-page::-webkit-scrollbar-thumb {
  display: none;
}
.popup-menu-container {
  position: absolute;
  left: 100px;
  top: 100px;
  z-index: 20;
  animation: popup 0.075s ease-in-out 0s 1;
}
.bookmark {
  height: 40px;
  width: 20px;
  padding: 0px;
  transform: rotate(0deg) skew(0deg);
  border-left: 10px solid red;
  border-right: 10px solid red;
  border-bottom: 10px solid transparent;
  position: fixed;
  top: 5px;
  right: 70px;
  z-index: 15;
}
.bookmark,
.bookmark:before,
.bookmark:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -50px;
}
`, "",{"version":3,"sources":["webpack://./src/containers/htmlViewer/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,SAAS;EACT,YAAY;EACZ,UAAU;EACV,yBAAiB;UAAjB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;;;EAGE,cAAc;AAChB;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,WAAW;EACX,wCAAwC;AAC1C;AACA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EAEZ,kCAAkC;EAClC,2BAA2B;EAC3B,4BAA4B;EAC5B,qCAAqC;EACrC,eAAe;EACf,QAAQ;EACR,WAAW;EACX,WAAW;AACb;AACA;;;EAGE,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;AAChC;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".html-viewer-page {\n  position: absolute;\n  left: 20px;\n  right: 20px;\n  top: 40px;\n  bottom: 40px;\n  z-index: 5;\n  user-select: text;\n}\n.scrolling-html-viewer-page {\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n.scrolling-html-viewer-page:hover::-webkit-scrollbar-thumb,\n.scrolling-html-viewer-page:active::-webkit-scrollbar-thumb,\n.scrolling-html-viewer-page:focus::-webkit-scrollbar-thumb {\n  display: block;\n}\n.scrolling-html-viewer-page::-webkit-scrollbar-thumb {\n  display: none;\n}\n.popup-menu-container {\n  position: absolute;\n  left: 100px;\n  top: 100px;\n  z-index: 20;\n  animation: popup 0.075s ease-in-out 0s 1;\n}\n.bookmark {\n  height: 40px;\n  width: 20px;\n  padding: 0px;\n  -webkit-transform: rotate(0deg) skew(0deg);\n  transform: rotate(0deg) skew(0deg);\n  border-left: 10px solid red;\n  border-right: 10px solid red;\n  border-bottom: 10px solid transparent;\n  position: fixed;\n  top: 5px;\n  right: 70px;\n  z-index: 15;\n}\n.bookmark,\n.bookmark:before,\n.bookmark:after {\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n}\n.spinner {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  margin-left: -150px;\n  margin-top: -50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
