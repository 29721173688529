// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-from-local {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 138px;
  margin-right: 25px;
  margin-top: 23px;
  min-width: 42px;
  height: 42px;
  opacity: 1;
  border-radius: 25px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
  z-index: 0;
  font-size: 15px;
  transition: 0.1s;
  font-weight: 500;
}

.import-book-box {
  position: relative;
  bottom: 67px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 500;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
}
.drag-section {
  position: absolute;
  top: 75px;
  width: calc(100% - 200px);
  height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
`, "",{"version":3,"sources":["webpack://./src/components/importLocal/importLocal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,QAAQ;EACR,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,eAAe;EACf,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,yBAAyB;EACzB,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb","sourcesContent":[".import-from-local {\n  position: fixed;\n  right: 0px;\n  top: 0px;\n  width: 138px;\n  margin-right: 25px;\n  margin-top: 23px;\n  min-width: 42px;\n  height: 42px;\n  opacity: 1;\n  border-radius: 25px;\n  line-height: 42px;\n  text-align: center;\n  cursor: pointer;\n  z-index: 0;\n  font-size: 15px;\n  transition: 0.1s;\n  font-weight: 500;\n}\n\n.import-book-box {\n  position: relative;\n  bottom: 67px;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  z-index: 500;\n  opacity: 0;\n  cursor: pointer;\n  font-size: 0;\n}\n.drag-section {\n  position: absolute;\n  top: 75px;\n  width: calc(100% - 200px);\n  height: calc(100vh - 90px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
