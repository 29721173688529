// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-chapter-name,
.header-book-name {
  width: 45vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  opacity: 0.3;
}
.header-container,
.footer-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 30px;
}
.header-container {
  position: absolute;
  top: 3px;
}
.footer-container {
  position: absolute;
  bottom: 3px;
}

.background-page-left,
.background-page-right {
  max-width: 45vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 30px;
  text-align: center;
  opacity: 0.3;
}
.bookmark {
  height: 40px;
  width: 20px;
  padding: 0px;
  transform: rotate(0deg) skew(0deg);
  border-left: 10px solid red;
  border-right: 10px solid red;
  border-bottom: 10px solid transparent;
  position: fixed;
  top: 5px;
  right: 70px;
  z-index: 15;
}
.bookmark,
.bookmark:before,
.bookmark:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/containers/pageWidget/background.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;AACA;;EAEE,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;;EAEE,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EAEZ,kCAAkC;EAClC,2BAA2B;EAC3B,4BAA4B;EAC5B,qCAAqC;EACrC,eAAe;EACf,QAAQ;EACR,WAAW;EACX,WAAW;AACb;AACA;;;EAGE,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;AAChC","sourcesContent":[".header-chapter-name,\n.header-book-name {\n  width: 45vw;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  line-height: 30px;\n  text-align: center;\n  opacity: 0.3;\n}\n.header-container,\n.footer-container {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  height: 30px;\n}\n.header-container {\n  position: absolute;\n  top: 3px;\n}\n.footer-container {\n  position: absolute;\n  bottom: 3px;\n}\n\n.background-page-left,\n.background-page-right {\n  max-width: 45vw;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  line-height: 30px;\n  text-align: center;\n  opacity: 0.3;\n}\n.bookmark {\n  height: 40px;\n  width: 20px;\n  padding: 0px;\n  -webkit-transform: rotate(0deg) skew(0deg);\n  transform: rotate(0deg) skew(0deg);\n  border-left: 10px solid red;\n  border-right: 10px solid red;\n  border-bottom: 10px solid transparent;\n  position: fixed;\n  top: 5px;\n  right: 70px;\n  z-index: 15;\n}\n.bookmark,\n.bookmark:before,\n.bookmark:after {\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
